import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import Layout from '@components/layout'
import Title from '@components/title'

const ThankYouPage = ({ uri }) => {
  useEffect(() => {
    let timer = setTimeout(() => navigate('/'), 60000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <Layout currentLocation={uri}>
      <section className="container section tc">
        <Title heading="h1" className="mb3" noUnderline>
          Kiitos!
        </Title>
        <p className="lh-text f8">Palaamme asiaan pikimmiten.</p>
      </section>
    </Layout>
  )
}

export default ThankYouPage
